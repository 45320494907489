<template>
  <div class="ether-list-orders">
    <div class="ether-list-orders__container">
      <ScrollList>
        <CustomTable
          :data="ordersList"
          :deleted-keys="tableOptions.deletedKeys"
          :rename-columns="tableOptions.renameColumn"
          :custom-order="tableOptions.customOrder"
          :is-action-block-visible="tableOptions.isActionBlockVisible"
          :direction="directionSort"
          :order="orderSort"
          :handleFilter="handleFilterOrder"
          :handleButton="handleAssignOrder"
          :name="'orders'"
        />
      </ScrollList>
    </div>
  </div>
</template>

<script>
import CustomTable from "../../molecules/CustomTable.vue";
import ScrollList from "../../atoms/ScrollList.vue";

export default {
  name: "EtherListDriver",
  props: {
    ordersList: {
      type: Array,
      default() {
        return [];
      },
    },
    directionSort: {
      type: Boolean,
      default: null,
    },
    orderSort: {
      type: String,
      default: "",
    },
    handleFilterOrder: {
      type: [Function, Boolean],
      default: null,
    },
    handleAssignOrder: {
      type: [Function, Boolean],
      default: null,
    },
  },
  data() {
    return {
      tableOptions: {
        deletedKeys: ["status"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "type_of_car_id",
            to: "Тип авто",
          },
          {
            from: "address_from",
            to: "Откуда",
          },
          {
            from: "address_to",
            to: "Куда",
          },
          {
            from: "client_name",
            to: "Заказчик",
          },
          {
            from: "client_phone",
            to: "Телефон",
          },
        ],
        customOrder: [
          "id",
          "type_of_car_id",
          "address_from",
          "address_to",
          "client_name",
          "client_phone",
          "status",
        ],
      },
    };
  },
  components: {
    CustomTable,
    ScrollList,
  },
};
</script>

<style lang="scss" scoped>
.ether-list-orders {
  width: 100%;
  height: 100%;
  &__container {
    width: 100%;
    height: 100%;
  }

  /deep/.custom-table__container {
    margin-top: 0;
  }
}
</style>
