<template>
  <div class="ether-orders">
    <div class="ether-orders__container">
      <div v-if="!assignOrder" class="ether-orders__header">
        <p class="ether-orders__title">Новые заказы</p>
      </div>
      <div class="ether-orders__content">
        <InputSearch
          v-if="!assignOrder"
          :label="'search'"
          :search="searchOrders"
          :placeholder="'Введите номер заказа'"
        />
        <ScrollList>
          <OrderCard
            :handleFocusOrder="handleFocusOrder"
            :order="order"
            v-for="order in ordersList"
            :key="order.id"
            :assignOrder="assignOrder"
            :handleAssignOrder="handleAssignOrder"
          />
        </ScrollList>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollList from "../../atoms/ScrollList.vue";
import OrderCard from "../../molecules/OrderCard.vue";
import InputSearch from "../../atoms/inputs/InputSearch.vue";

export default {
  name: "EtherOrders",
  props: {
    ordersList: {
      type: Array,
      default() {
        return [];
      },
    },
    assignOrder: {
      type: Boolean,
      default: false,
    },
    handleFocusOrder: {
      type: [Function, Boolean],
      default: false,
    },
    handleAssignOrder: {
      type: [Function, Boolean],
      default: false,
    },
    searchOrdersMain: {
      type: [Function, Boolean],
      default: false,
    },
  },
  components: {
    ScrollList,
    OrderCard,
    InputSearch,
  },
  computed: {},
  methods: {
    searchOrders(data) {
      this.searchOrdersMain(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.ether-orders {
  max-width: 100vh;
  &__container {
    @include flex-column;

    width: 100%;
    padding: 24px 18px 0;
  }
  &__header {
    padding-bottom: 22px;
  }
  &__title {
    font-size: $g-font-second-size;
  }
  &__content {
    height: calc(100vh - 70px);
  }
  /deep/.scroll-list {
    height: calc(100% - 56px);
  }
  /deep/.scroll-list__container {
    @include flex-column;
  }
  /deep/.search-input {
    margin-bottom: 20px;
  }
}
</style>
