<template>
  <button :class="['button-toggle', status ? 'on' : 'off']"><span /></button>
</template>

<script>
export default {
  name: "ButttonToggle",
  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button-toggle {
  position: relative;
  width: 40px;
  height: 24px;
  border-radius: 50px;
  background: $mainDisabled;
  box-shadow: inset -1px 1px 4px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  outline: none;
  border: none;

  span {
    position: absolute;
    display: block;
    top: 1px;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    transition: all ease-in-out 0.5s;
  }

  &.off {
    span {
      right: auto;
      left: 1px;
      background: $mainColor;
    }
  }

  &.on {
    span {
      right: 1px;
      left: auto;
      background: $mainSuccess;
    }
  }
}
</style>
