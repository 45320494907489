<template>
  <div class="confirm-modal-content">
    <slot name="description" />
    <div class="confirm-modal-content__buttons-container">
      <ButtonMain :buttonCancel="true" @click.native="onCancel" class="confirm-modal-content__btn">
        <slot name="cancel" />
      </ButtonMain>
      <ButtonMain class="confirm-modal-content__btn" @click.native="onSuccess">
        <slot name="success" />
      </ButtonMain>
    </div>
  </div>
</template>

<script>
import ButtonMain from "../atoms/buttons/ButtonMain.vue";

export default {
  name: "ConfirmModalContent",
  components: {
    ButtonMain,
  },
  props: {
    onCancel: {
      type: Function,
      default() {
        return {};
      },
    },
    onSuccess: {
      type: Function,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-modal-content {
  @include flex-row;

  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    font-size: $g-font-base-size;
    color: $g-color-white;
    padding-right: 80px;
    font-weight: normal;
  }

  &__buttons-container {
    display: flex;
    justify-content: space-between;
    width: 346px;
  }

  /deep/.button-main {
    width: 158px;
    height: 36px;
  }
}
</style>
