<template>
  <div class="search-input">
    <label :for="label">
      <input :id="label" v-model="value" type="text" :placeholder="placeholder" />
      <div class="search-input__button" @click="search(value)"><span>Искать</span></div>
    </label>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  name: "InputSearch",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    search: {
      type: [Function, Boolean],
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  input {
    width: 100%;
    background: $g-color-white;
    border-radius: 5px;
    height: 36px;
    border: 1px solid #131313;
    box-sizing: border-box;
    outline: none;
    font-size: 12px;
    padding: 17px 0 16px 16px;
    &::placeholder {
      color: $mainSecondTextColor;
      padding: 16px 0px;
      font-size: 12px;
    }
  }
  label {
    @include flex-row;
    position: relative;
  }
  &__button {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 13px;
    height: 100%;
    background: $mainColor;
    color: #fff;
    cursor: pointer;

    span {
      height: fit-content;
      font-size: $g-font-small-size;
    }
  }
}
</style>
