<template>
  <div @click="handleNotificationPage" class="notification-count">
    <div class="notification-count__icon">
      <Bell />
      <span v-if="notificationAll != 0" class="notification-count__read"></span>
    </div>
    <div v-if="notificationAll != 0" class="notification-count__number">
      {{ notificationAll }}
    </div>
  </div>
</template>

<script>
import ROUTE from "../../constants/routes";
import { mapState } from "vuex";

import Bell from "../../assets/images/icons/bell.svg";

export default {
  name: "NotificationCount",
  components: {
    Bell,
  },
  computed: {
    ...mapState("notification", ["notificationAll"]),
  },
  methods: {
    handleNotificationPage() {
      this.$router.push({
        path: ROUTE.NOTIFICATIONS_PAGE.replace(":page", 1),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-count {
  @include flex-row;

  align-items: center;
  cursor: pointer;

  &__icon {
    position: relative;
  }

  &__read {
    position: absolute;
    width: 10px;
    height: 10px;
    right: -1px;
    background: #eb5959;
    border-radius: 50px;
  }

  &__number {
    margin-left: 5px;
    padding: 5px 8px;
    font-size: 12px;
    background: #e5e5e5;
    border-radius: 50%;
  }
}
</style>
