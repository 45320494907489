<template>
  <div class="ether-map">
    <div ref="map" style="width: 100%; height: 100%"></div>
    <div v-if="label" ref="label" class="label">
      <p>{{ hoverItemName }}</p>
    </div>
  </div>
</template>

<script>
import View from "ol/View";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Cluster from "ol/source/Cluster";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import { Style, Icon, Text, Fill } from "ol/style";
import { fromLonLat, toLonLat, transform } from "ol/proj";
import { boundingExtent } from "ol/extent";

import "ol/ol.css";

export default {
  name: "EtherMap",
  data() {
    return {
      map: null,
      mapLat: 48.46521,
      mapLng: 35.0397828,
      mapDefaultZoom: 12,
      label: false,
      hoverItemId: "",
      orderLayer: [],
      driverLayerFree: [],
      driverLayerBusy: [],
    };
  },
  props: {
    assign: {
      type: Boolean,
      default: false,
    },
    orders: {
      type: [Array, Boolean],
      default() {
        return false;
      },
    },
    lat: {
      type: String,
      default: null,
    },
    lng: {
      type: String,
      default: null,
    },
    triggerCenter: {
      type: Boolean,
      default: false,
    },
    drivers: {
      type: [Array, Boolean],
      default() {
        return false;
      },
    },
    handleFocusOrder: {
      type: [Function],
      default() {
        return {};
      },
    },
    handleFocusDriver: {
      type: [Function],
      default() {
        return {};
      },
    },
  },
  methods: {
    initializeMap() {
      this.map = new Map({
        target: this.$refs["map"],
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          zoom: this.mapDefaultZoom,
          center: fromLonLat([this.mapLng, this.mapLat]),
          constrainResolution: true,
        }),
      });
      this.map.on("moveend", (e) => {
        const extent = this.map.getView().calculateExtent(this.map.getSize());
        const top = [];
        const bottom = [];
        top.push(extent[2]);
        top.push(extent[3]);
        bottom.push(extent[0]);
        bottom.push(extent[1]);
        this.$store.commit("common/setTop", toLonLat(top));
        this.$store.commit("common/setBottom", toLonLat(bottom));
      });
    },
    centerMap(lat, lng) {
      this.map.getView().setCenter(fromLonLat([lng, lat]));
      this.map.getView().setZoom(14);
    },
    addMapPointOrder(orders) {
      this.orderLayer = [];
      if (orders.length) {
        for (let i = 0; i < orders.length; i++) {
          if (orders[i] != null) {
            const vectorLayerOrder = new Feature({
              geometry: new Point(
                transform(
                  [parseFloat(orders[i].longitudeFrom), parseFloat(orders[i].latitudeFrom)],
                  "EPSG:4326",
                  "EPSG:3857",
                ),
              ),
            });
            vectorLayerOrder.setProperties({ name: "Order", id: orders[i].id });
            this.orderLayer.push(vectorLayerOrder);
          }
        }
      }
      const source = new VectorSource({
        features: this.orderLayer,
      });

      const clusterSource = new Cluster({
        distance: 15,
        minDistance: 2,
        source: source,
      });

      const styleCache = {};
      const clustersOrder = new VectorLayer({
        source: clusterSource,
        style: function (feature) {
          const size = feature.get("features").length;
          let style = styleCache[size];
          if (!style) {
            style = new Style({
              image: new Icon({
                anchor: [0.5, 0.6],
                anchorXUnits: "fraction",
                anchorYUnits: "fraction",
                src: "/images/img-map/new-order.png",
              }),
              text: new Text({
                text: size.toString() > 1 ? size.toString() : "",
                fill: new Fill({
                  color: "#000",
                }),
              }),
            });
            styleCache[size] = style;
          }
          return style;
        },
      });
      clustersOrder.set("name", "layerOrder");
      this.map.addLayer(clustersOrder);
    },
    addMapPointDriver(drivers) {
      this.driverLayerFree = [];
      this.driverLayerBusy = [];
      if (drivers.length) {
        for (let i = 0; i < drivers.length; i++) {
          if (this.assign ? drivers[i].status === "online" : drivers[i].free === true) {
            const vectorLayerDriverFree = new Feature({
              geometry: new Point(
                transform(
                  [parseFloat(drivers[i].longitude), parseFloat(drivers[i].latitude)],
                  "EPSG:4326",
                  "EPSG:3857",
                ),
              ),
            });
            vectorLayerDriverFree.setProperties({ name: "DriverFree", id: drivers[i].id });
            this.driverLayerFree.push(vectorLayerDriverFree);
          } else {
            const vectorLayerDriverBusy = new Feature({
              geometry: new Point(
                transform(
                  [parseFloat(drivers[i].longitude), parseFloat(drivers[i].latitude)],
                  "EPSG:4326",
                  "EPSG:3857",
                ),
              ),
            });
            vectorLayerDriverBusy.setProperties({ name: "DriverBusy", id: drivers[i].id });
            this.driverLayerBusy.push(vectorLayerDriverBusy);
          }
        }
        const sourceFree = new VectorSource({
          features: this.driverLayerFree,
        });
        const sourceBusy = new VectorSource({
          features: this.driverLayerBusy,
        });

        const clusterSourceFree = new Cluster({
          distance: 15,
          minDistance: 2,
          source: sourceFree,
        });
        const clusterSourceBusy = new Cluster({
          distance: 15,
          minDistance: 2,
          source: sourceBusy,
        });

        const styleCacheFree = {};
        const styleCacheBusy = {};
        const clustersDriverFree = new VectorLayer({
          source: clusterSourceFree,
          style: function (feature) {
            const size = feature.get("features").length;
            let style = styleCacheFree[size];
            if (!style) {
              style = new Style({
                image: new Icon({
                  anchor: [0.5, 0.6],
                  anchorXUnits: "fraction",
                  anchorYUnits: "fraction",
                  src: "/images/img-map/drivers-free.png",
                }),
                text: new Text({
                  text: size.toString() > 1 ? size.toString() : "",
                  fill: new Fill({
                    color: "#000",
                  }),
                }),
              });
              styleCacheFree[size] = style;
            }
            return style;
          },
        });
        const clustersDriverBusy = new VectorLayer({
          source: clusterSourceBusy,
          style: function (feature) {
            const size = feature.get("features").length;
            let style = styleCacheBusy[size];
            if (!style) {
              style = new Style({
                image: new Icon({
                  anchor: [0.5, 0.6],
                  anchorXUnits: "fraction",
                  anchorYUnits: "fraction",
                  src: "/images/img-map/drivers-busy.png",
                }),
                text: new Text({
                  text: size.toString() > 1 ? size.toString() : "",
                  fill: new Fill({
                    color: "#000",
                  }),
                }),
              });
              styleCacheBusy[size] = style;
            }
            return style;
          },
        });
        clustersDriverFree.set("name", "layerDriverFree");
        clustersDriverBusy.set("name", "layerDriverBusy");
        this.map.addLayer(clustersDriverBusy);
        this.map.addLayer(clustersDriverFree);
      }
    },
    eventMapHover() {
      this.map.on("pointermove", (event) => {
        this.map.getTarget().style.cursor = "default";
        this.label = false;
        this.hoverItemName = "";
        this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
          this.map.getTarget().style.cursor = "pointer";
          this.label = true;
          if (feature.getProperties().features[0].getProperties().name == "Order") {
            this.hoverItemName = "Новый заказ";
          }
          if (feature.getProperties().features[0].getProperties().name == "DriverFree") {
            this.hoverItemName = this.assign ? "Онлайн" : "Свободный";
          }
          if (feature.getProperties().features[0].getProperties().name == "DriverBusy") {
            this.hoverItemName = this.assign ? "Оффлайн" : "Занят";
          }
        });
      });
    },
    eventMapClick() {
      this.map.on("click", (event) => {
        this.map.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
          if (feature.getProperties().features.length > 1) {
            const extent = boundingExtent(
              feature.getProperties().features.map((r) => r.getGeometry().getCoordinates()),
            );
            this.map.getView().fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
          } else {
            if (!this.assign) {
              if (feature.getProperties().features[0].getProperties().name == "Order") {
                this.handleFocusOrder(feature.getProperties().features[0].getProperties().id);
              }
              if (
                feature.getProperties().features[0].getProperties().name == "DriverFree" ||
                feature.getProperties().features[0].getProperties().name == "DriverBusy"
              ) {
                this.handleFocusDriver(feature.getProperties().features[0].getProperties().id);
              }
            }
          }
        });
      });
    },
    deleteLayer(layerName) {
      const layersToRemove = [];
      this.map.getLayers().forEach((layer) => {
        if (layer?.get("name") != undefined && layer.get("name") === layerName) {
          layersToRemove.push(layer);
        }
      });
      const len = layersToRemove.length;
      for (var i = 0; i < len; i++) {
        this.map.removeLayer(layersToRemove[i]);
      }
    },
    labelMoving(e) {
      if (this.label) {
        this.$refs.label.style.left = `${e.pageX - 50}px`;
        this.$refs.label.style.top = `${e.pageY - 40}px`;
      }
    },
  },
  computed: {},
  watch: {
    orders: function (newVal, oldVal) {
      this.deleteLayer("layerOrder");
      this.addMapPointOrder(newVal);
    },
    drivers: function (newVal, oldVal) {
      this.deleteLayer("layerDriverFree");
      this.deleteLayer("layerDriverBusy");
      this.addMapPointDriver(newVal);
    },
    triggerCenter: function (newVal, oldVal) {
      this.centerMap(this.lat, this.lng);
    },
    $route(to, from) {
      this.map.updateSize();
    },
  },
  async mounted() {
    await this.initializeMap();
    await this.addMapPointOrder(this.orders);
    await this.addMapPointDriver(this.drivers);
    this.eventMapHover();
    this.eventMapClick();
    window.addEventListener("mousemove", this.labelMoving);
  },

  destroyed: function () {
    window.removeEventListener("mousemove", this.labelMoving);
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.ether-map {
  width: 100%;
  height: 100%;
  position: relative;
}
.pie-chart__container {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 100px;
  left: 100px;
}
.label {
  @include flex-column;

  align-content: center;
  position: fixed;
  width: 100px;
  height: 30px;
  background: white;
  border-radius: 5px;
  font-size: $g-font-small-size;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

  p {
    margin: auto;
  }

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    top: 100%;
    left: calc(50% - 10px);
  }
}
</style>
