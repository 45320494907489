<template>
  <div class="ether-list-drivers">
    <div class="ether-list-drivers__container">
      <ScrollList>
        <CustomTable
          :data="driversList"
          :deleted-keys="tableOptions.deletedKeys"
          :rename-columns="tableOptions.renameColumn"
          :custom-order="tableOptions.customOrder"
          :is-action-block-visible="tableOptions.isActionBlockVisible"
          :direction="directionSort"
          :order="orderSort"
          :handleFilter="handleFilterDriver"
          :handleButton="handleAssignDriver"
          :name="'driver'"
          :setDriverId="setDriverId"
        />
      </ScrollList>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import CustomTable from "../../molecules/CustomTable.vue";
import ScrollList from "../../atoms/ScrollList.vue";
export default {
  name: "EtherListDriver",
  props: {
    driversList: {
      type: Array,
      default() {
        return [];
      },
    },
    directionSort: {
      type: Boolean,
      default: null,
    },
    orderSort: {
      type: String,
      default: "",
    },
    handleFilterDriver: {
      type: [Function, Boolean],
      default: null,
    },
    handleAssignDriver: {
      type: [Function, Boolean],
      default: null,
    },
  },
  data() {
    return {
      tableOptions: {
        deletedKeys: ["status"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "last_name",
            to: "ФИО",
          },
          {
            from: "phone",
            to: "Телефон",
          },
          {
            from: "type_of_car_id",
            to: "Тип авто",
          },
          {
            from: "free",
            to: "Статус",
          },
        ],
        customOrder: ["id", "last_name", "phone", "type_of_car_id", "free", "status"],
      },
    };
  },
  components: {
    CustomTable,
    ScrollList,
  },
  computed: {
    ...mapState("common", ["driverChooseAll"]),
  },
  methods: {
    setDriverId(data) {
      if (data === "all") {
        if (this.driverChooseAll.length == 0) {
          const drivers = this.driversList;
          const arrayId = [];
          for (let i = 0; drivers.length > i; i++) {
            arrayId.push(drivers[i].id);
          }
          this.$store.commit("common/setDriverChooseAll", arrayId);
        } else {
          this.$store.commit("common/setDriverChooseNull");
        }
      } else {
        this.$store.commit("common/setDriverChoose", data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ether-list-drivers {
  width: 100%;
  height: 100%;
  &__container {
    width: 100%;
    height: 100%;
  }

  /deep/.custom-table__container {
    margin-top: 0;
  }
}
</style>
