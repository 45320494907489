<template>
  <div v-if="driver" :class="['ether-focus-driver', big ? 'big' : '']">
    <div class="ether-focus-driver__container">
      <div class="ether-focus-driver__content">
        <div class="ether-focus-driver__header">
          <div class="ether-focus-driver__type-car">{{ driver.car.typeOfCar.name }}</div>
          <div v-if="driver.free" class="ether-focus-driver__status free">Свободен</div>
          <div v-else class="ether-focus-driver__status-container">
            <div class="ether-focus-driver__status busy">Занят</div>
            <div v-if="driverActiveOrder != null" class="ether-focus-driver__number">
              Заказ №{{ driverActiveOrder.id }}
            </div>
          </div>
        </div>
        <div v-if="!big" class="ether-focus-driver__main">
          <div class="ether-focus-driver__item">
            <span class="ether-focus-driver__item-name">Имя водителя:</span> {{ driver.lastName }}
            {{ driver.firstName }} {{ driver.patronymic }}
          </div>
          <div class="ether-focus-driver__main-items">
            <div class="ether-focus-driver__main-item">
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Модель авто:</span>
                {{ driver.car.model }}
              </div>
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Номер авто:</span>
                {{ driver.car.carNumber }}
              </div>
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Телефон:</span>
                {{ driver.phone }}
              </div>
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Тип кузова:</span>
                {{ driver.car.bodyType.name }}
              </div>
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Марка:</span>
                {{ driver.car.brand.name }}
              </div>
            </div>
            <div class="ether-focus-driver__main-item">
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Год выпуска:</span>
                {{ driver.car.yearOfCarManufacture }}
              </div>
              <div v-if="driverActiveOrder != null" class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Откуда:</span>
                {{ driverActiveOrder.addressFromShort }}
              </div>
              <div v-if="driverActiveOrder != null" class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Куда:</span>
                {{ driverActiveOrder.addressToShort }}
              </div>
              <div v-if="driverActiveOrder != null" class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Время:</span>
                {{ driverActiveOrder.time }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="big" class="ether-focus-driver__main">
          <div class="ether-focus-driver__main-items">
            <div class="ether-focus-driver__main-item">
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Имя водителя:</span>
                {{ driver.lastName }} {{ driver.firstName }} {{ driver.patronymic }}
              </div>
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Марка:</span>
                {{ driver.car.brand.name }}
              </div>
            </div>
            <div class="ether-focus-driver__main-item">
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Модель:</span>
                {{ driver.car.model }}
              </div>
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Номер авто:</span>
                {{ driver.car.carNumber }}
              </div>
            </div>
            <div class="ether-focus-driver__main-item">
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Год выпуска:</span>
                {{ driver.car.yearOfCarManufacture }}
              </div>
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Телефон:</span>
                {{ driver.phone }}
              </div>
            </div>
            <div class="ether-focus-driver__main-item">
              <div class="ether-focus-driver__item">
                <span class="ether-focus-driver__item-name">Тип кузова:</span>
                {{ typeOfCar }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!big" class="ether-focus-driver__button">
        <ButtonMain @click.native="assignOrderToDriver(driver.id)">Поставить на заказ</ButtonMain>
        <ButtonMain @click.native="handleCenterMap(driver.latitude, driver.longitude)">
          Показать на карте
        </ButtonMain>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonMain from "../../atoms/buttons/ButtonMain.vue";
export default {
  name: "EtherFocusDriver",
  props: {
    assign: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
    driver: {
      type: [Object, Boolean],
      default: false,
    },
    driverActiveOrder: {
      type: [Object, Boolean],
      default: false,
    },
    handleCenterMap: {
      type: [Function, Boolean],
      default: false,
    },
    assignOrderToDriver: {
      type: [Function, Boolean],
      default: false,
    },
  },
  computed: {
    typeOfCar() {
      let type = "";
      if (this.driver.car.typeOfCar.id != 5) {
        type = `${this.driver.car.typeOfCar.name}, ${this.driver.car.typeOfCar.description}`;
      } else {
        type = `${this.driver.car.typeOfCar.name},
        До ${this.driver.car.weight} т, Д ${this.driver.car.length} м,
        Ш ${this.driver.car.width} м, В. ${this.driver.car.height} м, V ${this.driver.car.volume} м3`;
      }
      return type;
    },
  },
  components: {
    ButtonMain,
  },
};
</script>

<style lang="scss" scoped>
.ether-focus-driver {
  padding: 10px 0 7px;
  height: 100%;
  line-height: 22px;

  &__container {
    @include flex-row;

    height: 100%;
    padding: 10px 20px;
    border: $mainBorder;
  }

  &__content {
    flex-grow: 1;
  }

  &__button {
    width: 128px;
  }

  &__header {
    @include flex-row;

    align-items: center;
  }

  &__status-container {
    @include flex-row;
  }

  &__status {
    padding: 0 19px;

    &.free {
      color: $mainSuccess;
    }
    &.busy {
      color: $elementsColor;
    }
  }
  &__item {
    padding: 3px 0;
  }
  &__item-name {
    padding-right: 5px;
    color: $mainSecondTextColor;
  }
  &__main-items {
    @include flex-row;
  }
  &__main-item {
    padding-right: 25px;
  }
}
.ether-focus-driver.big {
  .ether-focus-driver__main-items {
    padding-top: 10px;
    justify-content: space-between;
  }
  .ether-focus-driver__item {
    font-size: 16px;
    padding: 3px 0;
  }
}
/deep/.button-main {
  margin-bottom: 10px;
  height: 48px;
}
@media (max-width: $break-lg) {
  .ether-focus-driver {
    &__item {
      font-size: 12px;
      padding: 2px 0;
    }
  }
  .ether-focus-driver.big {
    .ether-focus-driver__item {
      font-size: 16px;
      padding: 3px 0;
    }
  }
}
</style>
