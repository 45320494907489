<template>
  <Main>
    <div
      :class="[
        'ether l-section',
        triggerAssignDriver ? 'assign' : triggerAssignOrder ? 'assign-order' : '',
      ]"
    >
      <div class="ether__container">
        <EtherOrders
          v-if="!triggerAssignDriver && orders != null && (toogleMap || !triggerAssignOrder)"
          :handleFocusOrder="handleFocusOrder"
          :ordersList="orders.data"
          :assign-order="triggerAssignOrder"
          :handleAssignOrder="handleOpenAssignModal"
          :searchOrdersMain="searchOrdersMain"
        />
        <EtherDrivers
          v-if="(toogleMap || !triggerAssignDriver) && drivers != null && !triggerAssignOrder"
          :handleFocusDriver="handleFocusDriver"
          :handleFilter="handleFilterStatus"
          :handleAssignDriver="handleOpenAssignModal"
          :statusFilter="statusFilter"
          :driversList="drivers.data"
          :assign="triggerAssignDriver"
        />
        <EtherHeader
          :assign="triggerAssignDriver || triggerAssignOrder"
          :backToEther="backToEther"
        />
        <EtherMap
          v-if="
            (toogleMap || !triggerAssignDriver) &&
            (toogleMap || !triggerAssignOrder) &&
            (triggerAssignDriver ? order != null : orders != null) &&
            (triggerAssignOrder ? driver != null : drivers != null)
          "
          :assign="triggerAssignDriver || triggerAssignOrder"
          :handleFocusOrder="handleFocusOrder"
          :handleFocusDriver="handleFocusDriver"
          :orders="triggerAssignDriver ? [order] : orders.data"
          :drivers="triggerAssignOrder ? [driver] : drivers.data"
          :lat="lat"
          :lng="lng"
          :triggerCenter="triggerCenter"
        />
        <EtherListDriver
          :orderSort="orderSort"
          :directionSort="directionSort"
          :driversList="driversList"
          :handleFilterDriver="handleFilterDriver"
          :handleAssignDriver="handleOpenAssignModal"
          v-if="!toogleMap && triggerAssignDriver"
        />
        <EtherListOrder
          :orderSort="orderSort"
          :directionSort="directionSort"
          :ordersList="ordersList"
          :handleFilterOrder="handleFilterOrder"
          :handleAssignOrder="handleOpenAssignModal"
          v-if="!toogleMap && triggerAssignOrder"
        />
        <EtherFocusOrder
          :big="triggerAssignDriver"
          :assignDriverToOrder="assignDriverToOrder"
          v-if="order != null && focusTrigger && !triggerAssignOrder"
          :order="order"
          :assign="triggerAssignDriver"
          :handleCenterMap="handleCenterMap"
        />
        <EtherFocusDriver
          v-if="(driver != null && !focusTrigger) || triggerAssignOrder"
          :big="triggerAssignOrder"
          :handleCenterMap="handleCenterMap"
          :assignOrderToDriver="assignOrderToDriver"
          :assign="triggerAssignOrder"
          :driver="driver"
          :driverActiveOrder="driverActiveOrder"
        />
        <EtherTools
          v-if="triggerAssignDriver || triggerAssignOrder"
          :handleFilter="handleFilterStatus"
          :statusFilter="statusFilter"
          :handleToogleMap="handleToogleMap"
          :toogleMap="toogleMap"
          :searchDriver="searchDriver"
          :searchOrder="searchOrder"
          :assignOrder="triggerAssignOrder"
        />
      </div>
    </div>
    <DefaultModal
      class="ether__confirm-modal"
      v-if="assignModal"
      :on-close="handleCloseAssignModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseAssignModal" :on-success="handleAssignDriver">
        <template v-slot:description>
          <p v-if="triggerAssignDriver">
            Вы уверены, что хотите назначить заказу № {{ order.id }} водителя
            {{ driverAssign.lastName }} {{ driverAssign.firstName }}?
          </p>
          <p v-if="triggerAssignOrder">
            Вы уверены, что хотите назначить водителю {{ driver.lastName }}
            {{ driver.firstName }} заказ №{{ orderAssign.id }}?
          </p>
        </template>
        <template v-slot:cancel> Нет </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal class="ether__confirm-modal" v-if="sendSmsModal" :on-close="handleSendSmsModal">
      <div class="ether__send-sms">
        <ButtonMain @click.native="handleSendSms">Отправить СМС о новом заказе</ButtonMain>
      </div>
    </DefaultModal>
    <DefaultModal
      v-if="Object.keys(error).length != 0 || Object.keys(errorDriver).length != 0"
      :on-close="handleCloseErrorModal"
    >
      <ErrorModalContent :errorMessages="error.driverId" />
      <ErrorModalContent :errorMessages="[error.message]" />
      <ErrorModalContent :errorMessages="error.orderId" />
      <ErrorModalContent :errorMessages="[errorDriver.message]" />
    </DefaultModal>
  </Main>
</template>
<script>
import { mapState } from "vuex";
import ROUTE from "../constants/routes";
import keysToCamelCase from "camelcase-keys";
import { cleanDriverFilter, setDriverFilter, getDriverFilter } from "../helpers/userAuth";
import Main from "../templets/Main.vue";
import EtherOrders from "../components/sections/Ether/EtherOrders.vue";
import EtherDrivers from "../components/sections/Ether/EtherDrivers.vue";
import EtherMap from "../components/sections/Ether/EtherMap.vue";
import EtherHeader from "../components/sections/Ether/EtherHeader.vue";
import EtherFocusOrder from "../components/sections/Ether/EtherFocusOrder.vue";
import EtherTools from "../components/sections/Ether/EtherTools.vue";
import EtherListDriver from "../components/sections/Ether/EtherListDriver.vue";
import DefaultModal from "../components/molecules/DefaultModal.vue";
import ConfirmModalContent from "../components/molecules/ConfirmModalContent.vue";
import ErrorModalContent from "../components/molecules/ErrorModalContent.vue";
import EtherFocusDriver from "../components/sections/Ether/EtherFocusDriver.vue";
import EtherListOrder from "../components/sections/Ether/EtherListOrder.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
export default {
  name: "Ether",
  data() {
    return {
      statusFilter: true,
      focusTrigger: true,
      toogleMap: true,
      orderSort: "id",
      directionSort: false,
      assignModal: false,
      driverAssign: "",
      orderAssign: "",
      search: "",
      lng: null,
      lat: null,
      triggerCenter: false,
    };
  },
  components: {
    Main,
    EtherOrders,
    EtherDrivers,
    EtherMap,
    EtherHeader,
    EtherFocusOrder,
    EtherTools,
    EtherListDriver,
    DefaultModal,
    ConfirmModalContent,
    ErrorModalContent,
    EtherFocusDriver,
    EtherListOrder,
    ButtonMain,
  },
  methods: {
    backToEther() {
      this.$router.push({
        path: ROUTE.ETHER_PAGE,
      });
      this.getDriversList();
    },
    handleCenterMap(lat, lng) {
      this.lat = lat;
      this.lng = lng;
      this.triggerCenter = !this.triggerCenter;
    },
    getDriversList() {
      let url;
      if (this.statusFilter) {
        url = `?moderated=1&status=active&free=true`;
      } else {
        url = `?moderated=1&status=active`;
      }
      this.$store.dispatch("drivers/getDriversList", url);
    },
    getDriversForOrder(id) {
      let url = `/${id}`;
      if (this.statusFilter) {
        url = url + `?status=active`;
      } else {
        url = url + `?status=all`;
      }
      if (this.orderSort.length) {
        url = url + `&order=${this.orderSort}&direction=${this.directionSort ? "asc" : "desc"}`;
      }
      if (this.search.length) {
        url = url + `&search=${this.search}`;
      }
      this.$store.dispatch("drivers/getDriversForOrder", url);
    },
    getOrdersForDriver(id) {
      let url = `/${id}`;
      if (this.orderSort.length) {
        url = url + `?order=${this.orderSort}&direction=${this.directionSort ? "asc" : "desc"}`;
      }
      if (this.search.length) {
        url = url + `&search=${this.search}`;
      }
      this.$store.dispatch("order/getOrdersForDriver", url);
    },
    getOrdersList() {
      let url = `?driver=false&direction=desc&order=updated_at`;
      if (!this.triggerAssignDriver && !this.triggerAssignOrder) {
        url += "&status=expired,placed,waiting_dispatcher";
        if (this.search.length > 0) {
          url += `&search=${this.search.trim()}`;
        }
      } else {
        url += "&status=placed";
      }
      this.$store.dispatch("order/getOrdersList", url);
    },
    handleFilterStatus() {
      this.statusFilter = !this.statusFilter;
      cleanDriverFilter();
      setDriverFilter(this.statusFilter);
      if (this.triggerAssignDriver) {
        this.getDriversForOrder(this.$route.params.orderId);
      } else {
        this.getDriversList();
      }
    },
    handleFocusOrder(id) {
      if (!this.triggerAssignOrder) {
        this.focusTrigger = true;
        const url = `/${id}`;
        this.$store.dispatch("order/getOrderDetail", url).finally(() => {
          this.$store.commit("drivers/setDriver", null);
        });
      }
    },
    handleFocusDriver(id) {
      if (!this.triggerAssignDriver) {
        this.focusTrigger = false;
        const url = `/${id}`;
        this.$store.dispatch("drivers/getDriverDetail", url).finally(() => {
          this.$store.commit("order/setOrder", null);
        });
      }
    },
    assignDriverToOrder(id) {
      this.search = "";
      this.$router.push({
        path: ROUTE.ETHER_ADD_DRIVER_PAGE.replace(":orderId", id),
      });
      this.handleFocusOrder(id);
      this.getDriversForOrder(id);
    },
    assignOrderToDriver(id) {
      this.search = "";
      this.$router.push({
        path: ROUTE.ETHER_ADD_ORDER_PAGE.replace(":driverId", id),
      });
      this.getOrdersForDriver(id);
    },
    handleToogleMap(boolean) {
      this.toogleMap = boolean;
    },
    handleFilterDriver(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getDriversForOrder(this.$route.params.orderId);
    },
    handleFilterOrder(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getOrdersForDriver(this.$route.params.driverId);
    },
    handleAssignDriver() {
      if (this.triggerAssignOrder) {
        const data = {
          order_id: this.orderAssign.id,
          driver_id: this.driver.id,
        };
        this.handleCloseAssignModal();
        if (this.$route.query.reAssign == "true" && this.order.driver != null) {
          this.$store.dispatch("order/reAssignDriverToOrder", data);
        } else {
          this.$store.dispatch("order/assignDriverToOrder", data);
        }
      }
      if (this.triggerAssignDriver) {
        const data = {
          order_id: this.order.id,
          driver_id: this.driverAssign.id,
        };
        this.handleCloseAssignModal();
        if (this.$route.query.reAssign == "true" && this.order.driver != null) {
          this.$store.dispatch("order/reAssignDriverToOrder", data);
        } else {
          this.$store.dispatch("order/assignDriverToOrder", data);
        }
      }
    },
    handleOpenAssignModal(item) {
      this.assignModal = true;
      if (this.triggerAssignDriver) {
        this.driverAssign = item;
      }
      if (this.triggerAssignOrder) {
        this.orderAssign = item;
      }
    },
    handleCloseAssignModal() {
      this.assignModal = false;
      this.driverAssign = "";
    },
    handleCloseErrorModal() {
      this.$store.commit("order/setError", {});
      this.$store.commit("drivers/setErrorDriver", {});
      // if (this.triggerAssignDriver) {
      //   this.backToEther();
      // }
    },
    searchOrdersMain(data) {
      this.search = data;
      this.getOrdersList();
    },
    searchDriver(data) {
      this.search = data;
      this.getDriversForOrder(this.$route.params.orderId);
    },
    searchOrder(data) {
      this.search = data;
      this.getOrdersForDriver(this.$route.params.driverId);
    },
    getDriverFilter() {
      const filter = getDriverFilter();
      if (filter !== null) {
        this.statusFilter = filter;
      }
    },
    handleSendSmsModal() {
      this.$store.commit("common/setSendSmsModal", false);
    },
    handleSendSms() {
      let data;
      if (this.driverChoose.length > 199) {
        data = {};
      } else {
        data = {
          drivers: this.driverChoose,
        };
      }
      let url = `/${this.order.id}/notify/drivers`;
      if (this.driverChoose.length > 199) {
        url += "/all";
      }
      this.$store.dispatch("common/sendSmsDriver", { url: url, data: data });
    },
  },
  computed: {
    ...mapState("drivers", [
      "driver",
      "drivers",
      "driversList",
      "errorDriver",
      "driverActiveOrder",
    ]),
    ...mapState("order", ["orders", "order", "error", "ordersList"]),
    ...mapState("common", ["driverChoose", "sendSmsModal"]),
    triggerAssignDriver() {
      if (this.$route.params.orderId) {
        return true;
      }
      return false;
    },
    triggerAssignOrder() {
      if (this.$route.params.driverId) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getDriverFilter();
    this.getOrdersList();
    if (this.triggerAssignDriver) {
      const { params } = this.$route;
      const url = `/${params.orderId}`;
      this.$store.dispatch("order/getOrderDetail", url);
      this.getDriversForOrder(params.orderId);
    } else if (this.triggerAssignOrder) {
      const { params } = this.$route;
      const url = `/${params.driverId}`;
      this.$store.dispatch("drivers/getDriverDetail", url);
      this.getOrdersForDriver(this.$route.params.driverId);
    } else {
      this.$store.commit("order/setOrder", null);
      this.getDriversList();
    }
    window.Echo.channel("driver")
      .listen(".DriverUpdated", (e) => {
        if (!this.triggerAssignDriver) {
          const data = keysToCamelCase(e.model, { deep: true });
          if (data.moderated) {
            if (data.status == "online") {
              if (!this.statusFilter) {
                this.$store.commit("drivers/setNewDrivers", data);
                console.log("driver update +");
                console.log(data);
              } else {
                if (data.free == true) {
                  this.$store.commit("drivers/setNewDrivers", data);
                  console.log("driver update +");
                  console.log(data);
                } else {
                  this.$store.commit("drivers/setDeleteDrivers", data);
                  console.log("driver update -");
                  console.log(data);
                }
              }
            } else {
              this.$store.commit("drivers/setDeleteDrivers", data);
              console.log("driver update -");
              console.log(data);
            }
          } else {
            this.$store.commit("drivers/setDeleteDrivers", data);
            console.log("driver update -");
            console.log(data);
          }
        }
      })
      .listen(".DriverStored", (e) => {
        if (!this.triggerAssignDriver) {
          const data = keysToCamelCase(e.model, { deep: true });
          if (data.moderated) {
            if (data.status == "online") {
              if (!this.statusFilter) {
                this.$store.commit("drivers/setNewDrivers", data);
                console.log("driver stored +");
                console.log(data);
              } else {
                if (data.free == true) {
                  this.$store.commit("drivers/setNewDrivers", data);
                  console.log("driver stored +");
                  console.log(data);
                } else {
                  this.$store.commit("drivers/setDeleteDrivers", data);
                  console.log("driver stored -");
                  console.log(data);
                }
              }
            } else {
              this.$store.commit("drivers/setDeleteDrivers", data);
              console.log("driver stored -");
              console.log(data);
            }
          } else {
            this.$store.commit("drivers/setDeleteDrivers", data);
            console.log("driver stored -");
            console.log(data);
          }
        }
      })
      .listen(".DriverDeleted", (e) => {
        if (!this.triggerAssignDriver) {
          const data = keysToCamelCase(e.model, { deep: true });
          this.$store.commit("drivers/setDeleteDrivers", data);
          console.log("driver delete -");
          console.log(data);
        }
      });
    window.Echo.channel("order")
      .listen(".OrderUpdated", (e) => {
        const data = keysToCamelCase(e.model, { deep: true });
        if (data.status === "placed" && data.driver === null) {
          this.$store.commit("order/setNewOrders", data);
          console.log("order update +");
          console.log(data);
        } else {
          this.$store.commit("order/setDeleteOrder", data);
          console.log("order update -");
          console.log(data);
        }
      })
      .listen(".OrderCreated", (e) => {
        console.log("order created +");
        const data = keysToCamelCase(e.model, { deep: true });
        this.$store.commit("order/setNewOrders", data);
      })
      .listen(".OrderDeleted", (e) => {
        const data = keysToCamelCase(e.model, { deep: true });
        this.$store.commit("order/setDeleteOrder", data);
        console.log("order delete -");
        console.log(data);
      });
  },
  destroyed() {
    window.Echo.channel("order")
      .stopListening(".OrderUpdated")
      .stopListening(".OrderCreated")
      .stopListening(".OrderDeleted");
    window.Echo.channel("driver")
      .stopListening(".DriverUpdated")
      .stopListening(".DriverStored")
      .stopListening(".DriverDeleted");
    window.Echo.channel(`driverForOrder.${this.$route.params.orderId}`)
      .stopListening(".DriverUpdated")
      .stopListening(".DriverStored")
      .stopListening(".DriverDeleted");
    window.Echo.channel(`orderForDriver.${this.$route.params.driverId}`)
      .stopListening(".OrderUpdated")
      .stopListening(".OrderDeleted")
      .stopListening(".OrderCreated");
  },
  watch: {
    $route(to, from) {
      if (this.triggerAssignDriver) {
        window.Echo.channel(`driverForOrder.${this.$route.params.orderId}`)
          .listen(".DriverUpdated", (e) => {
            const data = keysToCamelCase(e.model, { deep: true });
            if (!this.statusFilter) {
              this.$store.commit("drivers/setNewDrivers", data);
              console.log("driverForOrder update +");
              console.log(data);
            } else {
              if (data.status == "online") {
                this.$store.commit("drivers/setNewDrivers", data);
                console.log("driverForOrder update +");
                console.log(data);
              } else {
                this.$store.commit("drivers/setDeleteDrivers", data);
                console.log("driverForOrder update -");
                console.log(data);
              }
            }
          })
          .listen(".DriverDeleted", (e) => {
            const data = keysToCamelCase(e.model, { deep: true });
            this.$store.commit("drivers/setDeleteDrivers", data);
            console.log("driverForOrder delete -");
            console.log(data);
          });
      } else if (this.triggerAssignOrder) {
        window.Echo.channel(`orderForDriver.${this.$route.params.driverId}`)
          .listen(".OrderUpdated", (e) => {
            const data = keysToCamelCase(e.model, { deep: true });
            this.$store.commit("order/setNewOrders", data);
            console.log("ordersForDriver update +");
            console.log(data);
          })
          .listen(".OrderDeleted", (e) => {
            const data = keysToCamelCase(e.model, { deep: true });
            this.$store.commit("order/setDeleteOrder", data);
            console.log("ordersForDriver delete -");
            console.log(data);
          })
          .listen(".OrderCreated", (e) => {
            const data = keysToCamelCase(e.model, { deep: true });
            this.$store.commit("order/setNewOrders", data);
            console.log("ordersForDriver created +");
            console.log(data);
          });
      } else {
        window.Echo.channel(`driverForOrder.${this.$route.params.orderId}`)
          .stopListening(".DriverUpdated")
          .stopListening(".DriverDeleted");
        this.getDriversList();
        this.$store.commit("order/setOrder", null);
        window.Echo.channel(`orderForDriver.${this.$route.params.driverId}`)
          .stopListening(".OrderUpdated")
          .stopListening(".OrderDeleted")
          .stopListening(".OrderCreated");
        this.getOrdersList();
        this.$store.commit("driver/setDriver", null);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ether {
  width: 100%;
  &__container {
    max-height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 283px 1fr 268px;
    grid-template-rows: 70px 1fr 260px;
  }
  .ether-orders {
    grid-column: 1/1;
    grid-row: 1/4;
  }
  .ether-drivers {
    grid-column: 3/3;
    grid-row: 1/4;
  }
  .focus-order {
    grid-column: 2/2;
    grid-row: 3/4;
  }
  .ether-header {
    grid-column: 2/2;
    grid-row: 1/2;
  }
  .ether-focus-driver {
    grid-column: 2/2;
    grid-row: 3/4;
  }
  .ether-map {
    grid-column: 2/2;
    grid-row: 2/3;
  }
}
.ether.assign {
  .ether__container {
    display: grid;
    grid-template-columns: 1fr 268px;
    grid-template-rows: 70px 170px 90px 1fr;
    width: 100%;
    max-height: 100vh;
  }
  .ether-header {
    grid-column: 1/3;
    grid-row: 1/2;
    padding: 0 17px;
  }
  /deep/.ether-header__container {
    width: 140px;
  }
  .ether-drivers {
    grid-column: 2/3;
    grid-row: 4/5;
    height: calc(100vh - 330px);
  }
  /deep/.ether-drivers__container {
    padding-top: 0;
  }
  .focus-order {
    grid-column: 1/3;
    grid-row: 2/3;
    padding: 0 17px;
  }
  .ether-map {
    grid-column: 1/2;
    grid-row: 4/5;
    height: calc(100vh - 330px);
    padding-left: 17px;
  }
  .ether-tools {
    grid-column: 1/3;
    grid-row: 3/4;
  }
  .ether-list-drivers {
    grid-column: 1/2;
    grid-row: 4/5;
    padding-left: 17px;
    height: calc(100vh - 330px);
  }
}
.ether.assign-order {
  .ether__container {
    display: grid;
    grid-template-columns: 268px 1fr;
    grid-template-rows: 70px 110px 70px 1fr;
    width: 100%;
    max-height: 100vh;
  }
  .ether-header {
    grid-column: 1/2;
    grid-row: 1/2;
    padding: 0 17px;
  }
  /deep/.ether-header__container {
    width: 140px;
  }
  .ether-orders {
    grid-column: 1/2;
    grid-row: 4/5;
    height: calc(100vh - 260px);
  }
  /deep/.ether-orders__container {
    padding: 0 0 0 17px;
    height: 100%;
  }
  .ether-focus-driver {
    grid-column: 1/3;
    grid-row: 2/3;
    padding: 0 17px;
  }
  .ether-map {
    grid-column: 2/3;
    grid-row: 4/5;
    height: calc(100vh - 260px);
    padding-right: 17px;
    padding-left: 10px;
  }
  .ether-tools {
    grid-column: 1/3;
    grid-row: 3/4;
  }
  .ether-list-orders {
    grid-column: 1/3;
    grid-row: 4/5;
    padding-left: 17px;
    padding-right: 17px;
    height: calc(100vh - 260px);
  }
}
.ether__send-sms {
  width: 100%;
  display: flex;
  justify-content: center;
  /deep/.button-main {
    width: fit-content;
  }
}
@media (max-width: $break-lg) {
  .ether {
    &__container {
      grid-template-columns: 283px 1fr 268px;
      grid-template-rows: 70px 1fr 230px;
    }
  }
}
/deep/.custom-table__action-block {
  justify-content: flex-end;
}
</style>
