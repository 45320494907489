<template>
  <div :class="['driver-card', inside ? 'inside' : '']" @click="handleFocusDriver(driver.id)">
    <div class="driver-card__container">
      <div class="driver-card__header">
        <div v-if="driver.car.typeOfCar != null" class="driver-card__type">
          {{ driver.car.typeOfCar.name }}
        </div>
        <div v-else class="driver-card__type">Не назначен</div>
        <div v-if="assign">
          <div v-if="driver.status === 'online'" class="driver-card__status free">Онлайн</div>
          <div v-else class="driver-card__status">Оффлайн</div>
        </div>
        <div v-else>
          <div v-if="driver.free" class="driver-card__status free">Свободен</div>
          <div v-else class="driver-card__status">Занят</div>
        </div>
      </div>
      <div class="driver-card__content">
        <p>{{ driver.lastName }} {{ driver.firstName }} {{ driver.patronymic }}</p>
        <p v-if="driver.car != null">
          {{ driver.car.brand.name }}
          {{ driver.car.model }}
        </p>
        <p v-if="assign">{{ driver.car.carNumber }}</p>
        <p>{{ driver.phone }}</p>
        <div class="driver-card__buttons">
          <ButtonMain v-if="assign" @click.native="handleAssignDriver(driver)"
            >Назначить</ButtonMain
          >
          <InputCheckboxMain
            v-if="assign"
            :id="driver.id"
            :handleGetId="setDriverId"
            :arrayId="driverChoose"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ButtonMain from "../atoms/buttons/ButtonMain.vue";
import InputCheckboxMain from "../atoms/inputs/InputCheckboxMain.vue";
export default {
  name: "DriverCard",
  props: {
    driver: {
      type: [Object, Boolean],
      default: false,
    },
    assign: {
      type: Boolean,
      default: false,
    },
    handleAssignDriver: {
      type: [Function, Boolean],
      default: false,
    },
    handleFocusDriver: {
      type: [Function, Boolean],
      default: false,
    },
  },
  components: {
    ButtonMain,
    InputCheckboxMain,
  },
  methods: {
    setDriverId(data) {
      this.$store.commit("common/setDriverChoose", data);
    },
  },
  computed: {
    ...mapState("common", ["top", "bottom", "driverChoose"]),
    inside() {
      if (
        this.top[0] > this.driver.longitude &&
        this.top[1] > this.driver.latitude &&
        this.bottom[0] < this.driver.longitude &&
        this.bottom[1] < this.driver.latitude
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.driver-card {
  background: $secondaryBackground;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: $g-font-small-size;
  line-height: 22px;

  &.inside {
    background-color: #daf2d4;
    order: -1;
  }

  &__container {
    padding: 6px 19px 6px 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      border: 1px solid $mainSecondColor;
    }
  }

  &__header {
    @include flex-row;

    justify-content: space-between;
  }

  &__type {
    font-weight: bold;
  }

  &__status {
    font-weight: bold;
    color: $elementsColor;

    &.free {
      color: $mainSuccess;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /deep/.button-main {
    width: 138px;
    height: 36px;
  }
}
</style>
