<template>
  <div class="ether-header">
    <div v-if="assign" class="ether-header__container">
      <ButtonMain :buttonGrey="true" @click.native="backToEther"> Назад в эфир </ButtonMain>
    </div>
    <div v-else class="ether-header__container_main">
      <div class="ether-header__button-group">
        <ButtonMain @click.native="handleCreateClientPage()">Создать заказчика</ButtonMain>
        <ButtonMain @click.native="handleCreateOrderPage()">Создать заказ</ButtonMain>
      </div>
      <NotificationCount />
    </div>
  </div>
</template>

<script>
import ROUTE from "../../../constants/routes";

import ButtonMain from "../../atoms/buttons/ButtonMain.vue";
import NotificationCount from "../../molecules/NotificationCount.vue";

export default {
  name: "EtherHeader",
  props: {
    assign: {
      type: Boolean,
      default: false,
    },
    backToEther: {
      type: [Function, Boolean],
      default: false,
    },
  },
  components: {
    ButtonMain,
    NotificationCount,
  },
  methods: {
    handleCreateClientPage() {
      this.$router.push({
        path: ROUTE.CLIENT_CREATE_PAGE.replace(":process", "create").replace(":clientId", "new"),
      });
    },
    handleCreateOrderPage() {
      this.$router.push({
        path: ROUTE.ORDER_CREATE_PAGE.replace(":process", "create").replace(":orderId", "new"),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ether-header {
  display: flex;

  &__container {
    @include flex-row;

    width: 336px;
    margin: auto 0;
    gap: 20px;
  }
  &__container_main {
    @include flex-row;

    width: 100%;
    justify-content: space-between;
  }
  &__button-group {
    @include flex-row;

    width: 336px;
    margin: auto 0;
    gap: 20px;
  }
}
</style>
