<template>
  <div class="ether-drivers">
    <div class="ether-drivers__container">
      <div v-if="!assign" class="ether-drivers__header">
        <p class="ether-drivers__title">Водители</p>
        <div class="ether-drivers__filter">
          <div class="ether-drivers__filter-title"><p>Показать только свободных</p></div>
          <ButtonToggle @click.native="handleFilter" :status="statusFilter" />
        </div>
      </div>
      <div class="ether-drivers__content">
        <ScrollList>
          <DriverCard
            :handleAssignDriver="handleAssignDriver"
            :handleFocusDriver="handleFocusDriver"
            :driver="driver"
            :assign="assign"
            v-for="driver in driversList"
            :key="driver.id"
          />
        </ScrollList>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollList from "../../atoms/ScrollList.vue";
import ButtonToggle from "../../atoms/buttons/ButtonToggle.vue";
import DriverCard from "../../molecules/DriverCard.vue";
export default {
  name: "EtherDrivers",
  data() {
    return {};
  },
  props: {
    driversList: {
      type: Array,
      default() {
        return [];
      },
    },
    statusFilter: {
      type: Boolean,
      default: false,
    },
    handleFilter: {
      type: [Function, Boolean],
      default: false,
    },
    handleAssignDriver: {
      type: [Function, Boolean],
      default: false,
    },
    assign: {
      type: Boolean,
      default: false,
    },
    handleFocusDriver: {
      type: [Function, Boolean],
      default: false,
    },
  },
  components: {
    ScrollList,
    ButtonToggle,
    DriverCard,
  },
};
</script>

<style lang="scss" scoped>
.ether-drivers {
  max-height: 100vh;
  height: 100%;

  &__container {
    @include flex-column;

    height: 100%;
    padding: 16px 2px 0 18px;
  }

  &__header {
    padding-bottom: 6px;
    padding-right: 15px;
  }

  &__title {
    font-size: $g-font-second-size;
  }

  &__content {
    height: 100%;
    overflow: hidden;
  }

  &__button {
    width: fit-content;
  }

  &__filter {
    @include flex-row;

    justify-content: space-between;
  }

  &__filter-title {
    display: flex;
    align-items: center;
    font-size: $g-font-small-size;
  }

  /deep/.scroll-list__container {
    @include flex-column;
  }
}
</style>
