<template>
  <div class="focus-order" v-if="order">
    <div :class="['ether-focus-order', big ? 'big' : '']">
      <div class="ether-focus-order__container">
        <div class="ether-focus-order__content">
          <div class="ether-focus-order__numder">
            Заказ <span>{{ order.id }}</span>
          </div>
          <div class="ether-focus-order__items">
            <div>
              <p>
                <span class="ether-focus-order__item-name">Откуда:</span>
                {{ order.addressFromShort }}
              </p>
              <p>
                <span class="ether-focus-order__item-name">Куда:</span> {{ order.addressToShort }}
              </p>
              <p><span class="ether-focus-order__item-name">Дата:</span> {{ order.date }}</p>
              <p><span class="ether-focus-order__item-name">Время:</span> {{ order.time }}</p>
              <p v-if="big">
                <span class="ether-focus-order__item-name">Количество часов:</span>
                {{ order.countOfHours }} ч.
                {{ order.countOfMinutes === null ? "00" : order.countOfMinutes }} мин.
              </p>
            </div>
            <div v-if="big">
              <p>
                <span class="ether-focus-order__item-name">Тариф:</span> {{ order.rate }} грн/час
              </p>
              <p>
                <span class="ether-focus-order__item-name">Страховка:</span
                >{{ order.insurance ? "Есть" : "Нет" }}
              </p>
              <p v-if="order.insurance">
                <span class="ether-focus-order__item-name">Оценка груза:</span>
                {{ order.costEstimate }} грн
              </p>
              <p>
                <span class="ether-focus-order__item-name">Грузчики, тариф в час:</span>
                {{ moversRate }} грн
              </p>
              <p>
                <span class="ether-focus-order__item-name">Количество:</span
                >{{ order.numberOfMovers || "0" }}
              </p>
            </div>
            <div>
              <p v-if="big"><span class="ether-focus-order__item-name">Радиус:</span> 0</p>
              <p>
                <span class="ether-focus-order__item-name">Тип авто:</span>
                {{ order.typeOfCar.name }}
              </p>
              <p>{{ typeOfCar }}</p>
              <p v-if="big">
                <span class="ether-focus-order__item-name">Цена:</span> {{ order.totalPrice }} грн
              </p>
            </div>
            <div v-if="!big">
              <p><span class="ether-focus-order__item-name">Радиус:</span> 0</p>
              <p>
                <span class="ether-focus-order__item-name">Тариф:</span> {{ order.rate }} грн/час
              </p>
              <p>
                <span class="ether-focus-order__item-name">Грузчики:</span>
                {{ order.numberOfMovers || "0" }}
              </p>
              <p>
                <span class="ether-focus-order__item-name">Цена:</span> {{ order.totalPrice }} грн
              </p>
            </div>
            <div>
              <p>
                <span class="ether-focus-order__item-name">Заказчик:</span> {{ order.client.name }}
              </p>
              <p>
                <span class="ether-focus-order__item-name">Телефон:</span> {{ order.client.phone }}
              </p>
              <p v-if="big" class="ether-focus-order__item-description">
                <span class="ether-focus-order__item-name">Описание:</span>
                {{ order.description || "Нет" }}
              </p>
              <p v-if="big">
                <span class="ether-focus-order__item-name">Особености:</span> {{ cargoFeatures }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="!big" class="ether-focus-order__button">
          <ButtonMain
            @click.native="handleCenterMap(order.latitudeFrom, order.longitudeFrom)"
            buttonGreen
          >
            Показать на карте
          </ButtonMain>
          <ButtonMain @click.native="toOrder(order.id)">Детали</ButtonMain>
          <ButtonMain v-if="order.status != 'expired'" @click.native="assignDriverToOrder(order.id)"
            >Назначить водителя</ButtonMain
          >
        </div>
      </div>
    </div>
    <div v-if="order.driver != null && big && !assign" class="focus-order-driver">
      <div class="focus-order-driver">
        <div class="focus-order-driver__container">
          <p>
            <span class="ether-focus-order__item-name">Водитель:</span>
            {{ order.driver.lastName }} {{ order.driver.firstName }} {{ order.driver.patronymic }}
          </p>
          <p><span class="ether-focus-order__item-name">Телефон:</span> {{ order.driver.phone }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "../../../constants/routes";

import ButtonMain from "../../atoms/buttons/ButtonMain.vue";
export default {
  name: "EtherFocusOrder",
  props: {
    big: {
      type: Boolean,
      default: false,
    },
    assign: {
      type: Boolean,
      default: false,
    },
    order: {
      type: [Object, Boolean],
      default: false,
    },
    assignDriverToOrder: {
      type: [Function, Boolean],
      default: false,
    },
    handleCenterMap: {
      type: [Function, Boolean],
      default: false,
    },
  },
  computed: {
    typeOfCar() {
      let type = "";
      if (this.order.typeOfCar.id != 5) {
        type = `${this.order.typeOfCar.description}`;
      } else {
        type = `${this.order.typeOfCar.name},
        До ${this.order.weight} т, Д ${this.order.length} м,
        Ш ${this.order.width} м, В. ${this.order.height} м, V ${this.order.volume} м3`;
      }
      return type;
    },
    ...mapState("settings", ["settings"]),
    moversRate() {
      return this.settings?.data?.[0].value;
    },
    cargoFeatures() {
      let features = "";
      if (this.order.cargoFeatures.length) {
        for (let i = 0; i < this.order.cargoFeatures.length; i++) {
          if (i === 0) {
            features += this.order.cargoFeatures[i].name;
          } else {
            features += `, ${this.order.cargoFeatures[i].name}`;
          }
        }
      } else {
        features = "нет";
      }
      return features;
    },
  },
  methods: {
    getSettings() {
      this.$store.dispatch("settings/getSettings");
    },
    toOrder(id) {
      this.$router.push({
        path: ROUTE.ORDER_DETAIL_PAGE.replace(":process", "update").replace(":orderId", id),
      });
    },
  },
  components: {
    ButtonMain,
  },
  mounted() {
    this.getSettings();
  },
};
</script>

<style lang="scss" scoped>
.ether-focus-order {
  padding: 10px 0 7px;
  height: 100%;
  line-height: 22px;

  &__container {
    @include flex-row;

    height: 100%;
    padding: 7px 20px;
    border: $mainBorder;
  }

  &__content {
    flex-grow: 1;
  }

  &__button {
    width: 128px;
  }

  &__item-description {
    max-width: 400px;
    max-height: 45px;
    overflow: hidden;
  }

  &__numder {
    padding-bottom: 7px;
  }

  &__items {
    column-count: 2;
    font-size: 14px;

    div {
      width: fit-content;
    }

    p {
      padding-bottom: 0px;
      padding-bottom: 4px;
    }
  }
  &__item-name {
    padding-right: 5px;
    color: $mainSecondTextColor;
  }
}
.ether-focus-order.big {
  padding: 0;

  .ether-focus-order__items {
    @include flex-row;

    font-size: $g-font-base-size;
    justify-content: space-between;

    p {
      padding-bottom: 2px;
    }
  }
}
.focus-order-driver {
  &__container {
    @include flex-column;

    height: 100%;
    padding: 15px 20px;
    border: $mainBorder;
    border-top: none;
    font-size: $g-font-base-size;

    p {
      padding-bottom: 8px;
    }
  }
}
/deep/.button-main {
  margin-bottom: 10px;
}
@media (max-width: $break-lg) {
  .ether-focus-order {
    &__items {
      font-size: 12px;

      p {
        padding-bottom: 0px;
      }
    }
  }
}
</style>
