<template>
  <div
    :class="[
      'order-card',
      inside && 'inside',
      order.status.key === 'expired' && 'expired',
      order.status.key === 'waiting_dispatcher' && 'waiting',
    ]"
  >
    <div
      v-if="order.status.key === 'expired' && 'expired' && !assignOrder"
      class="order-card__prompt"
    >
      Просроченный заказ.
    </div>
    <div
      v-else-if="order.status.key === 'waiting_dispatcher' && 'waiting' && !assignOrder"
      class="order-card__prompt"
    >
      Новый заказ. Нужно его промодерировать.
    </div>
    <div v-else-if="'waiting' && !assignOrder" class="order-card__prompt">
      Опубликованный заказ.
    </div>
    <div class="order-card__container" @click="handleFocusOrder(order.id)">
      <p>
        <span>Заказ №</span> <span>{{ order.id }}</span>
      </p>
      <p>
        <span>Откуда: </span><span>{{ order.addressFromShort }}</span>
      </p>
      <p>
        <span>Куда: </span><span>{{ order.addressToShort }}</span>
      </p>
      <p>
        <span>{{ order.totalPrice }}</span
        ><span class="pr-10"> грн</span> <span>{{ order.distance }}</span
        ><span>км</span>
      </p>
      <p>{{ typeOfCar }}</p>
      <ButtonMain v-if="assignOrder" @click.native="handleAssignOrder(order)">Назначить</ButtonMain>
      <div
        v-if="order.status.key === 'expired' || order.status.key === 'waiting_dispatcher'"
        @click="toUpdateOrder(order.id)"
        class="order-card__button-edit"
      >
        <Pencil />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "../../constants/routes";

import ButtonMain from "../atoms/buttons/ButtonMain.vue";
import Pencil from "../../assets/images/icons/pencil-small.svg";

export default {
  name: "OrderCard",
  props: {
    order: {
      type: [Object, Boolean],
      default: false,
    },
    handleFocusOrder: {
      type: [Function, Boolean],
      default: false,
    },
    assignOrder: {
      type: Boolean,
      default: false,
    },
    handleAssignOrder: {
      type: [Function, Boolean],
      default: false,
    },
  },
  components: {
    ButtonMain,
    Pencil,
  },
  computed: {
    typeOfCar() {
      let type = "";
      if (this.order?.typeOfCar?.id != 5) {
        type = `${this.order.typeOfCar?.description}`;
      } else {
        type = `${this.order.typeOfCar.name},
        До ${this.order.weight} т, Д ${this.order.length} м,
        Ш ${this.order.width} м, В. ${this.order.height} м, V ${this.order.volume} м3`;
      }
      return type;
    },
    ...mapState("common", ["top", "bottom"]),
    inside() {
      if (
        this.top[0] > this.order.longitudeFrom &&
        this.top[1] > this.order.latitudeFrom &&
        this.bottom[0] < this.order.longitudeFrom &&
        this.bottom[1] < this.order.latitudeFrom
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    toUpdateOrder(id) {
      this.$router.push({
        path: ROUTE.ORDER_DETAIL_PAGE.replace(":orderId", id),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.order-card {
  position: relative;
  background: $secondaryBackground;
  border-radius: 5px;
  margin-bottom: 10px;

  &:hover {
    .order-card__prompt {
      display: block;
    }
  }
  &.inside {
    background-color: #daf2d4;
    order: -1;
  }
  &.expired {
    background: $secondaryBackground;
    order: -2;
    border: 2px solid #eb5959;
  }

  &.waiting {
    background: $secondaryBackground;
    order: -3;
    border: 2px solid blue;
  }
  &__container {
    position: relative;
    padding: 6px 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border: 1px solid $mainSecondColor;
    }
  }

  &__button-edit {
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background: $mainColor;

    &:hover {
      opacity: 0.7;
    }

    path {
      fill: #fff;
    }
  }

  p {
    font-size: $g-font-small-size;
    line-height: 22px;
  }

  &__prompt {
    position: absolute;
    display: none;
    max-width: 240px;
    min-width: 200px;
    padding: 20px 10px;
    background: #2f2f34;
    opacity: 0.95;
    border-radius: 5px;
    color: #fff;
    left: 0;
    bottom: 0;
    z-index: 1000;
  }

  /deep/.button-main {
    width: 136px;
    height: 36px;
  }
}
</style>
