<template>
  <div v-if="errorMessages.length" class="error-modal-content">
    <p v-for="error in errorMessages" :key="error.id" class="error-modal-content__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ErroeModalContent",
  props: {
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.error-modal-content {
  @include flex-row;

  justify-content: center;
  width: 100%;

  &__error {
    color: $elementsColor;
  }
}
</style>
