<template>
  <div class="scroll-list">
    <div class="scroll-list__container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ScrollList",
};
</script>

<style lang="scss" scoped>
.scroll-list {
  @include scrollBar;

  scrollbar-color: $mainDisabled $g-color-grey;
  scrollbar-width: thin;
  height: 100%;
  overflow-y: auto;
  padding-right: 5px;

  &__container {
    height: fit-content;
  }
}
</style>
