<template>
  <div class="ether-tools">
    <div class="ether-tools__container">
      <h1 v-if="!assignOrder">Выбрать водителя</h1>
      <div class="ether-tools__items">
        <div class="ether-tools__toggle-map">
          <div
            :class="['ether-tools__toggle-item', toogleMap ? 'active' : '']"
            @click="handleToogleMap(true)"
          >
            Карта
          </div>
          <div
            :class="['ether-tools__toggle-item', toogleMap ? '' : 'active']"
            @click="handleToogleMap(false)"
          >
            Список
          </div>
        </div>
        <div v-if="!assignOrder" class="ether-tools__search">
          <InputSearch
            :label="'search'"
            :search="searchDriver"
            :placeholder="'Введите ФИО или телефон водителя'"
          />
        </div>
        <div v-else class="ether-tools__search">
          <InputSearch
            :label="'search'"
            :search="searchOrder"
            :placeholder="'Введите номер заказа'"
          />
        </div>
        <div v-if="!assignOrder" class="ether-tools__filter">
          <div class="ether-tools__filter-title"><p>Показать только онлайн</p></div>
          <ButtonToggle @click.native="handleFilter" :status="statusFilter" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonToggle from "../../atoms/buttons/ButtonToggle.vue";
import InputSearch from "../../atoms/inputs/InputSearch.vue";

export default {
  name: "EtherTools",
  components: {
    ButtonToggle,
    InputSearch,
  },
  props: {
    statusFilter: {
      type: Boolean,
      default: false,
    },
    handleFilter: {
      type: [Function, Boolean],
      default: false,
    },
    handleToogleMap: {
      type: [Function, Boolean],
      default: false,
    },
    toogleMap: {
      type: Boolean,
      default: true,
    },
    searchDriver: {
      type: [Function, Boolean],
      default: false,
    },
    searchOrder: {
      type: [Function, Boolean],
      default: false,
    },
    assignOrder: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ether-tools {
  &__container {
    @include flex-column;

    justify-content: space-between;
    height: 100%;
    padding: 15px 0 15px 17px;
  }
  &__items {
    @include flex-row;

    justify-content: space-between;
  }
  &__filter {
    @include flex-row;

    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    width: 250px;
  }
  &__filter-title {
    display: flex;
    align-items: center;
    font-size: $g-font-base-size;
    padding-right: 20px;
  }
  &__toggle-map {
    @include flex-row;

    overflow: hidden;
    width: 328px;
    height: 36px;
    border-radius: 5px;
  }

  &__toggle-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    background: $mainDisabled;
    font-weight: 500;
    color: $mainColor;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    &.active {
      background: $mainColor;
      color: $g-color-white;
    }
  }

  &__search {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;

    /deep/.search-input {
      width: 350px;
    }
  }
}
</style>
